// External Dependencies
import React from 'react'
import { Pane } from 'evergreen-ui'

type Props = {
  styles?: any
  children: React.ReactNode
}
const Context = ({ styles, children }: Props) => {
  return (
    <Pane position="relative">
      <Pane
        marginTop={50}
        display="flex"
        alignItems="center"
        justifyContent="center"
        {...styles}
      >
        {children}
      </Pane>
    </Pane>
  )
}

export default Context
