// External Dependencies
import React from 'react'
// @ts-ignore
import Tabletop from 'tabletop'
import { Pie, Bar } from 'react-chartjs-2'
import { useMedia } from 'react-use-media'
import {
  Pane,
  Heading,
  Table,
  Spinner,
  TextInput,
  Button,
  toaster,
} from 'evergreen-ui'
import logo from 'assets/images/logo.png'

// Our Dependencies
import { Header, Content } from 'components'

type ResponseProps = {
  'Business Location': string
  Gender: string
  'Age Range': string
  'Do you know what type of loan you are interested in ?': string
  'What is your employment status ?': string
  'Have you applied for/received a loan from JN Small Business Loans (not JN Bank) in the last ONE year ?': string
  'Do you hold an ACTIVE JN Bank account? (You can apply even if you are NOT an account holder)': string
  'Have you visited our website, www.jnsbl.com, to see our loan offerings ?': string
  'Do you want more information about JNSBL and our offerings ?': string
  Timestamp: string
}

const TotalSubmission = ({
  total,
  monthlyTotal,
}: {
  total: number
  monthlyTotal: number
}) => (
  <Pane
    height="100%"
    color="#234361"
    display="flex"
    fontFamily="Bozon"
    alignItems="center"
    justifyContent="center"
  >
    <Pane is="h2" fontWeight={400} fontSize={45} marginY={0}>
      {total === -1 ? <Spinner color="white" /> : total}
    </Pane>
    <Pane is="span" marginLeft={5} marginTop={20} marginRight={10}>
      Total Leads
    </Pane>
    <Pane is="span" marginLeft={5} marginTop={20}>
      /
    </Pane>
    <Pane is="h2" fontWeight={400} fontSize={45} marginY={0} marginLeft={10}>
      {monthlyTotal === -1 ? <Spinner color="white" /> : monthlyTotal}
    </Pane>
    <Pane is="span" marginLeft={5} marginTop={20}>
      This Month
    </Pane>
  </Pane>
)

const Auth = (props: any) => {
  const [password, setPassword] = React.useState('')
  return (
    <Pane textAlign="center">
      <Pane>
        <img src={logo} alt="logo" width={'175px'} />
      </Pane>
      <Pane
        is="h2"
        fontSize={24}
        color="#3D3F5A"
        fontWeight={500}
        textAlign="center"
        fontFamily="Bozon"
      >
        Welcome Team JNSBL! You must have a password to proceed further.
      </Pane>
      <Pane
        is="h2"
        fontSize={18}
        color="#3D3F5A"
        fontWeight={300}
        textAlign="center"
        fontFamily="Bozon"
      >
        Fill in the password provided by Start Social to continue.
      </Pane>
      <TextInput
        type="password"
        placeholder="Enter Password"
        marginRight={15}
        marginTop={30}
        value={password}
        onChange={(e: any) => setPassword(e.target.value)}
      />
      <Button
        appearance="primary"
        onClick={(e: any) => {
          e.preventDefault()
          if (password === process.env.REACT_APP_PASSWORD) {
            props.setAuth(true)
          } else {
            toaster.danger('Invalid Password')
          }
        }}
      >
        Submit
      </Button>
    </Pane>
  )
}

const Metrics = () => {
  const isWide = useMedia({ minWidth: 800 })
  const [isAuth, setAuth] = React.useState(false)
  const [businessParishes, setBusinessParishes] = React.useState({})
  const [genders, setGenders] = React.useState({})
  const [ages, setAges] = React.useState({})
  const [totalSubmissions, setTotalSubmissions] = React.useState(-1)
  const [loanType, setLoanTypes] = React.useState({})
  const [employmentStatus, setEmploymentStatus] = React.useState({})
  const [existingCustomer, setExistingCustomer] = React.useState({})
  const [activeAccount, setActiveAccount] = React.useState({})
  const [visitedWebsite, setVisitedWebsite] = React.useState({})
  const [moreInfo, setMoreInfo] = React.useState({})
  const [currentMonthTotal, setMonthTotal] = React.useState(-1)

  React.useEffect(
    () => {
      async function init() {
        try {
          let _businessParishes: { [key: string]: any } = {}
          let _genders: { [key: string]: any } = {}
          let _ages: { [key: string]: any } = {}
          let _loanType: { [key: string]: any } = {}
          let _employmentStatus: { [key: string]: any } = {}
          let _existingCustomer: { [key: string]: any } = {}
          let _activeAccount: { [key: string]: any } = {}
          let _visitedWebsite: { [key: string]: any } = {}
          let _moreInfo: { [key: string]: any } = {}
          var d = new Date()
          var currentMonth = d.getMonth()
          var currentYear = d.getFullYear()
          let _monthlyTotal = 0
          let data: any
          data = Object.values(
            await Tabletop.init({
              key: process.env.REACT_APP_SPREADSHEET_KEY,
            }),
          )[0]

          if (data?.elements) {
            data.elements.forEach((response: ResponseProps) => {
              const _activeAccountKey =
                response[
                  'Do you hold an ACTIVE JN Bank account? (You can apply even if you are NOT an account holder)'
                ]
              const _visitedWebsiteKey =
                response[
                  'Have you visited our website, www.jnsbl.com, to see our loan offerings ?'
                ]
              const _moreInfoKey =
                response[
                  'Do you want more information about JNSBL and our offerings ?'
                ]
              const _parishKey = response['Business Location']
              const _genderKey = response['Gender']
              const _ageKey = response['Age Range']
              const _existingCustomerKey =
                response[
                  'Have you applied for/received a loan from JN Small Business Loans (not JN Bank) in the last ONE year ?'
                ]
              const _loanTypeKey =
                response[
                  'Do you know what type of loan you are interested in ?'
                ]
              const _employmentStatusKey =
                response['What is your employment status ?']

              if (
                new Date(response.Timestamp).getFullYear() === currentYear &&
                new Date(response.Timestamp).getMonth() === currentMonth
              ) {
                _monthlyTotal++
              }

              if (_activeAccount.hasOwnProperty(_activeAccountKey)) {
                _activeAccount[_activeAccountKey]++
              } else {
                _activeAccount[_activeAccountKey] = 1
              }

              if (_visitedWebsite.hasOwnProperty(_visitedWebsiteKey)) {
                _visitedWebsite[_visitedWebsiteKey]++
              } else {
                _visitedWebsite[_visitedWebsiteKey] = 1
              }

              if (_moreInfo.hasOwnProperty(_moreInfoKey)) {
                _moreInfo[_moreInfoKey]++
              } else {
                _moreInfo[_moreInfoKey] = 1
              }

              if (_businessParishes.hasOwnProperty(_parishKey)) {
                _businessParishes[_parishKey]++
              } else {
                _businessParishes[_parishKey] = 1
              }

              if (_genders.hasOwnProperty(_genderKey)) {
                _genders[_genderKey]++
              } else {
                _genders[_genderKey] = 1
              }

              if (_ages.hasOwnProperty(_ageKey)) {
                _ages[_ageKey]++
              } else {
                _ages[_ageKey] = 1
              }

              if (_loanType.hasOwnProperty(_loanTypeKey)) {
                _loanType[_loanTypeKey]++
              } else {
                _loanType[_loanTypeKey] = 1
              }

              if (_existingCustomer.hasOwnProperty(_existingCustomerKey)) {
                _existingCustomer[_existingCustomerKey]++
              } else {
                _existingCustomer[_existingCustomerKey] = 1
              }

              if (_employmentStatus.hasOwnProperty(_employmentStatusKey)) {
                _employmentStatus[_employmentStatusKey]++
              } else {
                _employmentStatus[_employmentStatusKey] = 1
              }
            })
            setActiveAccount(_activeAccount)
            setVisitedWebsite(_visitedWebsite)
            setMoreInfo(_moreInfo)
            setLoanTypes(_loanType)
            setEmploymentStatus(_employmentStatus)
            setExistingCustomer(_existingCustomer)
            setBusinessParishes(_businessParishes)
            setGenders(_genders)
            setAges(_ages)
            setMonthTotal(_monthlyTotal)
            setTotalSubmissions(data.elements.length)
          }
        } catch (error) {
          console.error(error)
        }
      }

      init()
    },
    [
      //businessParishes,
      //totalSubmissions,
      //businessStatus
    ],
  )
  return isAuth ? (
    <Pane>
      <Header
        bottomComponent={
          <TotalSubmission
            total={totalSubmissions}
            monthlyTotal={currentMonthTotal}
          />
        }
      />
      <Content
        styles={{
          marginX: 30,
          marginBottom: 80,
          flexDirection: 'column',
        }}
      >
        <Heading is="h3" size={800} marginBottom={20} float={'left'}>
          Demographics
        </Heading>
        <Pane
          display="flex"
          flexWrap={'wrap'}
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={isWide ? 'row' : 'column'}
          maxWidth="1400px"
        >
          <Pane
            padding={15}
            elevation={2}
            height={420}
            borderRadius={5}
            flex="1 0 20%"
            width="100%"
            marginRight={isWide ? 15 : 0}
            marginTop={15}
          >
            <Heading size={500} marginBottom={20}>
              Business Location
            </Heading>
            <Table>
              <Table.Head height={35}>
                <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                <Table.TextHeaderCell>Count</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height={465}>
                {Object.entries(businessParishes).map((parish: any, index) => (
                  <Table.Row key={index}>
                    <Table.TextCell>{parish[0]}</Table.TextCell>
                    <Table.TextCell>{parish[1]}</Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Pane>
          <Pane
            padding={15}
            elevation={2}
            height={420}
            borderRadius={5}
            flex="0 0 50%"
            marginRight={isWide ? 15 : 0}
            marginTop={15}
            width="100%"
          >
            <Heading size={500} marginBottom={20}>
              Genders
            </Heading>
            <Pane width="350px" height="350px" marginX="auto" marginY="0">
              <Pie
                data={{
                  labels: Object.keys(genders),
                  datasets: [
                    {
                      backgroundColor: [
                        '#4C3D5E',
                        '#F069A6',
                        '#F48370',
                        '#F88869',
                      ],
                      hoverBackgroundColor: [
                        '#4C3D5E',
                        '#F069A6',
                        '#F48370',
                        '#F88869',
                      ],
                      data: Object.values(genders),
                    },
                  ],
                }}
                width={100}
                height={100}
              />
            </Pane>
          </Pane>
          <Pane
            padding={15}
            elevation={2}
            height={420}
            borderRadius={5}
            flex="1 0 20%"
            marginTop={15}
            width="100%"
          >
            <Heading size={500} marginBottom={20}>
              Ages
            </Heading>
            <Table>
              <Table.Head height={35}>
                <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                <Table.TextHeaderCell>Count</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height={465}>
                {Object.entries(ages).map((ageRange: any, index) => (
                  <Table.Row key={index}>
                    <Table.TextCell>{ageRange[0]}</Table.TextCell>
                    <Table.TextCell>{ageRange[1]}</Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Pane>
        </Pane>
        <Heading
          is="h3"
          size={800}
          marginBottom={20}
          marginTop={40}
          float={'left'}
        >
          Service Interest
        </Heading>
        <Pane
          display="flex"
          flexWrap={'wrap'}
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={isWide ? 'row' : 'column'}
          maxWidth="1400px"
        >
          <Pane
            padding={15}
            elevation={2}
            height={420}
            borderRadius={5}
            flex="1 0 30%"
            width="100%"
            marginRight={isWide ? 15 : 0}
            marginTop={15}
          >
            <Heading size={500} marginBottom={20}>
              Existing Customers
            </Heading>
            <Pane width="350px" height="350px" marginX="auto" marginY="0">
              <Pie
                data={{
                  labels: Object.keys(existingCustomer),
                  datasets: [
                    {
                      backgroundColor: ['#4C3D5E', '#F88869'],
                      hoverBackgroundColor: ['#4C3D5E', '#F88869'],
                      data: Object.values(existingCustomer),
                    },
                  ],
                }}
                width={100}
                height={100}
              />
            </Pane>
          </Pane>
          <Pane
            padding={15}
            elevation={2}
            height={420}
            borderRadius={5}
            flex="1 0 40%"
            width="100%"
            marginRight={isWide ? 15 : 0}
            marginTop={15}
          >
            <Heading size={500} marginBottom={20}>
              Loan Types
            </Heading>
            <Pane width="350px" height="350px" marginX="auto" marginY="0">
              <Bar
                data={{
                  labels: Object.keys(loanType),
                  datasets: [
                    {
                      backgroundColor: 'rgba(255,99,132,0.2)',
                      borderColor: 'rgba(255,99,132,1)',
                      borderWidth: 1,
                      hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                      hoverBorderColor: 'rgba(255,99,132,1)',
                      data: Object.values(loanType),
                    },
                  ],
                }}
                legend={null}
                options={{
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          stepSize: 1,
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </Pane>
          </Pane>
          <Pane
            padding={15}
            elevation={2}
            height={420}
            borderRadius={5}
            flex="1 0 20%"
            width="100%"
            marginTop={15}
          >
            <Heading size={500} marginBottom={20}>
              Employment Status
            </Heading>
            <Table>
              <Table.Head height={35}>
                <Table.TextHeaderCell>Status</Table.TextHeaderCell>
                <Table.TextHeaderCell>Count</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height={465}>
                {Object.entries(employmentStatus).map((status: any, index) => (
                  <Table.Row key={index}>
                    <Table.TextCell>{status[0]}</Table.TextCell>
                    <Table.TextCell>{status[1]}</Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Pane>
        </Pane>
        <Heading
          is="h3"
          size={800}
          marginBottom={20}
          marginTop={40}
          float={'left'}
        >
          Other
        </Heading>
        <Pane
          display="flex"
          flexWrap={'wrap'}
          width="100%"
          alignItems="center"
          justifyContent="center"
          flexDirection={isWide ? 'row' : 'column'}
          maxWidth="1400px"
        >
          <Pane
            padding={15}
            elevation={2}
            height={420}
            borderRadius={5}
            flex="1 0 30%"
            width="100%"
            marginRight={isWide ? 15 : 0}
            marginTop={15}
          >
            <Heading size={500} marginBottom={20}>
              JN Bank Members
            </Heading>
            <Table>
              <Table.Head height={35}>
                <Table.TextHeaderCell>Active Member</Table.TextHeaderCell>
                <Table.TextHeaderCell>Count</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height={465}>
                {Object.entries(activeAccount).map((account: any, index) => (
                  <Table.Row key={index}>
                    <Table.TextCell>{account[0]}</Table.TextCell>
                    <Table.TextCell>{account[1]}</Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Pane>
          <Pane
            padding={15}
            elevation={2}
            height={420}
            borderRadius={5}
            flex="1 0 30%"
            width="100%"
            marginRight={isWide ? 15 : 0}
            marginTop={15}
          >
            <Heading size={500} marginBottom={20}>
              Visited Website
            </Heading>
            <Table>
              <Table.Head height={35}>
                <Table.TextHeaderCell>Visited Website</Table.TextHeaderCell>
                <Table.TextHeaderCell>Count</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height={465}>
                {Object.entries(visitedWebsite).map((visit: any, index) => (
                  <Table.Row key={index}>
                    <Table.TextCell>{visit[0]}</Table.TextCell>
                    <Table.TextCell>{visit[1]}</Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Pane>
          <Pane
            padding={15}
            elevation={2}
            height={420}
            borderRadius={5}
            flex="1 0 30%"
            width="100%"
            marginTop={15}
          >
            <Heading size={500} marginBottom={20}>
              Interested in More Information
            </Heading>
            <Table>
              <Table.Head height={35}>
                <Table.TextHeaderCell>Interested</Table.TextHeaderCell>
                <Table.TextHeaderCell>Count</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height={465}>
                {Object.entries(moreInfo).map((info: any, index) => (
                  <Table.Row key={index}>
                    <Table.TextCell>{info[0]}</Table.TextCell>
                    <Table.TextCell>{info[1]}</Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Pane>
        </Pane>
      </Content>
    </Pane>
  ) : (
    <Pane
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginTop={'30vh'}
    >
      <Auth setAuth={setAuth} />
    </Pane>
  )
}

export default Metrics
