// External Dependencies
import React, { useState, useRef, useReducer } from 'react'
import { useMedia } from 'react-use-media'
import { useHistory } from 'react-router-dom'

import {
  Pane,
  Label,
  Button,
  Link,
  Text,
  Tooltip,
  Radio,
  SelectField,
  TextInputField,
  toaster,
  Tablist,
  Tab,
  Icon,
} from 'evergreen-ui'

// Our Dependencies
import { Header, Content, PhoneNumberInput } from 'components'
import { ReactComponent as Home } from 'assets/images/home.svg'

type FormData = {
  firstName: string
  middleName: string
  lastName: string
  personalLocation: string
  businessLocation: string
  email: string
  number1: string
  number2: string
  number3: string
  gender: string
  age: string
  loanType: string
  employmentStatus: string
  guarantor: string
  loanAmount: string
  appliedBefore: string
  activeAccount: string
  visitedWebsite: string
  contactMethod: string
  moreInfo: string
}

type SectionProps = {
  isWide: boolean
  isLoading: boolean
  setActiveSection: any
  setSelectedSection: any
  formRef?: any
  formData: FormData
  setFormData: any
  isSelected?: boolean
  isCompleted?: boolean
}
const formFields = {
  firstName: '',
  middleName: '',
  lastName: '',
  personalLocation: 'Hanover',
  businessLocation: 'Hanover',
  email: '',
  number1: '',
  number2: '',
  number3: '',
  gender: 'Male',
  age: 'Below 18',
  loanType: '',
  employmentStatus: '',
  guarantor: '',
  loanAmount: '',
  appliedBefore: '',
  activeAccount: '',
  visitedWebsite: '',
  contactMethod: '',
  moreInfo: '',
}

type ReducerTypes = {
  field: string
  value: string
}

function reducer(state: FormData, { field, value }: ReducerTypes) {
  return { ...state, [field]: value }
}

const SectionOne = ({
  isWide,
  isLoading,
  setActiveSection,
  setSelectedSection,
  formRef,
  setFormData,
  formData,
  isSelected,
  isCompleted,
}: SectionProps) => {
  return isCompleted ? (
    <Pane display={isSelected ? '' : 'none'}>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
      >
        <TextInputField
          required
          inputHeight={38}
          label="First Name"
          placeholder="John"
          name="entry.1974635530"
          flexGrow={1}
          paddingRight={isWide ? 15 : 0}
          value={formData.firstName}
          onChange={(e: any) => {
            setFormData('firstName', e.target.value)
          }}
        />
        <TextInputField
          inputHeight={38}
          label="Middle Name"
          placeholder="A."
          name="entry.1639514299"
          flexGrow={1}
          paddingRight={isWide ? 15 : 0}
          value={formData.middleName}
          onChange={(e: any) => {
            setFormData('middleName', e.target.value)
          }}
        />
        <TextInputField
          required
          label="Last Name"
          inputHeight={38}
          placeholder="Brown"
          name="entry.1598938198"
          flexGrow={1}
          value={formData.lastName}
          onChange={(e: any) => {
            setFormData('lastName', e.target.value)
          }}
        />
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
        marginTop={15}
      >
        <SelectField
          required
          inputHeight={38}
          label="Personal Location"
          marginTop={isWide ? 0 : 20}
          name="entry.1723143888"
          flex="0 0 50%"
          paddingRight={isWide ? 15 : 0}
          value={formData.personalLocation}
          onChange={(e: any) => {
            setFormData('personalLocation', e.target.value)
          }}
        >
          <option value="Hanover" defaultValue="Hanover">
            Hanover
          </option>
          <option value="Saint Elizabeth">Saint Elizabeth</option>
          <option value="Saint James">Saint James</option>
          <option value="Trelawny">Trelawny</option>
          <option value="Westmoreland">Westmoreland</option>
          <option value="Clarendon">Clarendon</option>
          <option value="Manchester">Manchester</option>
          <option value="Saint Ann">Saint Ann</option>
          <option value="Saint Catherine">Saint Catherine</option>
          <option value="Saint Mary">Saint Mary</option>
          <option value="Kingston">Kingston</option>
          <option value="Saint Andrew">Saint Andrew</option>
          <option value="Portland">Portland</option>
          <option value="Saint Thomas">Saint Thomas</option>
        </SelectField>
        <SelectField
          inputHeight={38}
          label="Business Location"
          marginTop={isWide ? 0 : 20}
          name="entry.887775859"
          flex="0 0 50%"
          value={formData.businessLocation}
          onChange={(e: any) => {
            setFormData('businessLocation', e.target.value)
          }}
        >
          <option value="Hanover" defaultValue="Hanover">
            Hanover
          </option>
          <option value="Saint Elizabeth">Saint Elizabeth</option>
          <option value="Saint James">Saint James</option>
          <option value="Trelawny">Trelawny</option>
          <option value="Westmoreland">Westmoreland</option>
          <option value="Clarendon">Clarendon</option>
          <option value="Manchester">Manchester</option>
          <option value="Saint Ann">Saint Ann</option>
          <option value="Saint Catherine">Saint Catherine</option>
          <option value="Saint Mary">Saint Mary</option>
          <option value="Kingston">Kingston</option>
          <option value="Saint Andrew">Saint Andrew</option>
          <option value="Portland">Portland</option>
          <option value="Saint Thomas">Saint Thomas</option>
          <option value="N/A">Rather Not Say</option>
        </SelectField>
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
        marginTop={15}
      >
        <Pane flex="1 0 50%">
          <TextInputField
            required
            type="email"
            inputHeight={38}
            label="Email Address"
            name="entry.127113590"
            placeholder="johnbrown@gmail.com"
            paddingRight={isWide ? 15 : 0}
            width={'100%'}
            value={formData.email}
            onChange={(e: any) => {
              setFormData('email', e.target.value)
            }}
          />
        </Pane>
        <Pane flex="1 0 50%">
          <PhoneNumberInput
            isRequired={true}
            placeholder="Primary Number"
            name="entry.1103470692"
            inputWidth={'100%'}
            label={'Primary Phone Number'}
            value={formData.number1}
            onChange={(e: any) => {
              setFormData('number1', e.target.value)
            }}
          />
        </Pane>
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
        marginTop={5}
      >
        <Pane flex="0 0 50%" paddingRight={isWide ? 15 : 0}>
          <PhoneNumberInput
            placeholder="Secondary  Number"
            name="entry.1677231076"
            inputWidth={'100%'}
            label={'Secondary Phone Number'}
            value={formData.number2}
            onChange={(e: any) => {
              setFormData('number2', e.target.value)
            }}
          />
        </Pane>
        <Pane flex="0 0 50%">
          <PhoneNumberInput
            placeholder="Whatsapp Number"
            name="entry.266162112"
            inputWidth={'100%'}
            label={'Whatsapp Number'}
            value={formData.number3}
            onChange={(e: any) => {
              setFormData('number3', e.target.value)
            }}
          />
        </Pane>
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
        marginTop={15}
      >
        <SelectField
          inputHeight={38}
          label="I identify my gender as *"
          marginTop={isWide ? 0 : 20}
          name="entry.1852578199"
          flex="0 0 50%"
          paddingRight={isWide ? 15 : 0}
          value={formData.gender}
          onChange={(e: any) => {
            setFormData('gender', e.target.value)
          }}
        >
          <option value="Male" defaultValue="Male">
            Male
          </option>
          <option value="Female">Female</option>
          <option value="Prefer not to disclose">Prefer not to disclose</option>
        </SelectField>
        <SelectField
          inputHeight={38}
          label="Age Range *"
          marginTop={isWide ? 0 : 20}
          name="entry.1174628624"
          flex="0 0 50%"
          value={formData.age}
          onChange={(e: any) => {
            setFormData('age', e.target.value)
          }}
        >
          <option value="Below 18">Below 18</option>
          <option value="18-24 years old" defaultValue="18-24 years old">
            18-24 years old
          </option>
          <option value="25-39 years old">25-39 years old</option>
          <option value="40-64 years old">40-64 years old</option>
          <option value="65 plus">65 plus</option>
        </SelectField>
      </Pane>
      <Button
        float={'right'}
        height={40}
        onClick={(e: any) => {
          e.preventDefault()
          if (formRef.current.reportValidity()) {
            setActiveSection([0, 1])
            setSelectedSection(1)
          }
        }}
        marginTop={20}
        isLoading={isLoading}
        appearance="primary"
        alignSelf="flex-end"
        color="white"
        style={{
          backgroundImage: isLoading
            ? 'none'
            : 'linear-gradient(39deg, #1453A8 0%, #124ea9 100%)',
        }}
      >
        Next
      </Button>
    </Pane>
  ) : null
}

const SectionTwo = ({
  isWide,
  isLoading,
  setActiveSection,
  setSelectedSection,
  formRef,
  setFormData,
  formData,
  isSelected,
  isCompleted,
}: SectionProps) => {
  const [isUnemployed, setUnemployed] = useState(
    formData.employmentStatus === 'unemployed',
  )
  return isCompleted ? (
    <Pane display={isSelected ? '' : 'none'}>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
      >
        <Pane flexGrow={1}>
          <Label appearance="primary">
            Do you know what type of loan you are interested in ?
          </Label>
          <Radio
            checked={formData.loanType === 'business'}
            onChange={(e) => {
              setFormData('loanType', e.target.value)
            }}
            size={16}
            name="entry.421374965"
            label="Business"
            value="business"
            isRequired
          />
          <Radio
            checked={formData.loanType === 'personal'}
            onChange={(e) => {
              setFormData('loanType', e.target.value)
            }}
            size={16}
            name="entry.421374965"
            label="Personal"
            value="personal"
            isRequired
          />
          <Radio
            checked={formData.loanType === 'both'}
            onChange={(e) => {
              setFormData('loanType', e.target.value)
            }}
            size={16}
            name="entry.421374965"
            label="Both"
            value="both"
            isRequired
          />
          <Radio
            checked={formData.loanType === 'unsure'}
            onChange={(e) => {
              setFormData('loanType', e.target.value)
            }}
            size={16}
            name="entry.421374965"
            label={
              <React.Fragment>
                Unsure{' '}
                <Link href="https://www.jnsbl.com/" target="_blank">
                  Find out more
                </Link>
              </React.Fragment>
            }
            value="unsure"
            isRequired
          />
        </Pane>
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
        marginTop={20}
      >
        <Pane flexGrow={1}>
          <Label appearance="primary">What is your employment status ?</Label>
          <Radio
            checked={formData.employmentStatus === 'employed'}
            onChange={(e) => {
              if (e.target.value === 'unemployed') {
                setUnemployed(true)
              } else {
                setUnemployed(false)
              }
              setFormData('employmentStatus', e.target.value)
            }}
            size={16}
            name="entry.1629762133"
            label="Employed"
            value="employed"
            isRequired
          />
          <Radio
            checked={formData.employmentStatus === 'Self Employed'}
            onChange={(e) => {
              if (e.target.value === 'unemployed') {
                setUnemployed(true)
              } else {
                setUnemployed(false)
              }
              setFormData('employmentStatus', e.target.value)
            }}
            size={16}
            name="entry.1629762133"
            label="Self Employed"
            value="Self Employed"
            isRequired
          />
          <Radio
            checked={formData.employmentStatus === 'Registered Business Holder'}
            onChange={(e) => {
              if (e.target.value === 'unemployed') {
                setUnemployed(true)
              } else {
                setUnemployed(false)
              }
              setFormData('employmentStatus', e.target.value)
            }}
            size={16}
            name="entry.1629762133"
            label="Registered Business Holder"
            value="Registered Business Holder"
            isRequired
          />
          <Radio
            checked={formData.employmentStatus === 'unemployed'}
            onChange={(e) => {
              if (e.target.value === 'unemployed') {
                setUnemployed(true)
              } else {
                setUnemployed(false)
              }
              setFormData('employmentStatus', e.target.value)
            }}
            size={16}
            name="entry.1629762133"
            label="Unemployed"
            value="unemployed"
            isRequired
          />
        </Pane>
        {isUnemployed && (
          <Pane flexGrow={1}>
            <Label appearance="primary">Do you have a Guarantor ?</Label>
            <Tooltip content="A guarantor is a person who agrees to repay the borrower's debt should the borrower default on agreed repayments">
              <Icon icon="info-sign" color="info" marginLeft={5} />
            </Tooltip>
            <Radio
              checked={formData.guarantor === 'Yes'}
              onChange={(e) => {
                setFormData('guarantor', e.target.value)
              }}
              size={16}
              name="entry.890429435"
              label="Yes"
              value="Yes"
              isRequired
            />
            <Radio
              checked={formData.guarantor === 'No'}
              onChange={(e) => {
                setFormData('guarantor', e.target.value)
              }}
              size={16}
              name="entry.890429435"
              label="No"
              value="No"
              isRequired
            />
          </Pane>
        )}
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
        marginTop={20}
      >
        <Pane flexGrow={1}>
          <Label appearance="primary">How much do you want to access ?</Label>
          <Radio
            checked={formData.loanAmount === 'Less than 100K'}
            onChange={(e) => {
              setFormData('loanAmount', e.target.value)
            }}
            size={16}
            name="entry.75983905"
            label="Less than 100K"
            value="Less than 100K"
            isRequired
          />
          <Radio
            checked={formData.loanAmount === '100 – 500K'}
            onChange={(e) => {
              setFormData('loanAmount', e.target.value)
            }}
            size={16}
            name="entry.75983905"
            label="100 – 500K"
            value="100 – 500K"
            isRequired
          />
          <Radio
            checked={formData.loanAmount === '500 – 1M'}
            onChange={(e) => {
              setFormData('loanAmount', e.target.value)
            }}
            size={16}
            name="entry.75983905"
            label="500 – 1M"
            value="500 – 1M"
            isRequired
          />
          <Radio
            checked={formData.loanAmount === '1 – 1.5M'}
            onChange={(e) => {
              setFormData('loanAmount', e.target.value)
            }}
            size={16}
            name="entry.75983905"
            label="1 – 1.5M"
            value="1 – 1.5M"
            isRequired
          />
          <Radio
            checked={formData.loanAmount === '1.5M plus'}
            onChange={(e) => {
              setFormData('loanAmount', e.target.value)
            }}
            size={16}
            name="entry.75983905"
            label="1.5M plus"
            value="1.5M plus"
            isRequired
          />
        </Pane>
      </Pane>
      <Pane display="flex" justifyContent="space-between">
        <Button
          height={40}
          onClick={(e: any) => {
            e.preventDefault()
            setActiveSection([0])
            setSelectedSection(0)
          }}
          marginTop={20}
          appearance="primary"
          color="white"
          style={{
            backgroundImage: 'linear-gradient(39deg, #1453A8 0%, #124ea9 100%)',
          }}
        >
          Previous
        </Button>
        <Button
          height={40}
          onClick={(e: any) => {
            e.preventDefault()
            if (formRef.current.reportValidity()) {
              setActiveSection([0, 1, 2])
              setSelectedSection(2)
            }
          }}
          marginTop={20}
          isLoading={isLoading}
          appearance="primary"
          color="white"
          style={{
            backgroundImage: isLoading
              ? 'none'
              : 'linear-gradient(39deg, #1453A8 0%, #124ea9 100%)',
          }}
        >
          Next
        </Button>
      </Pane>
    </Pane>
  ) : null
}

const SectionThree = ({
  isWide,
  isLoading,
  setActiveSection,
  setSelectedSection,
  formRef,
  setFormData,
  formData,
  isSelected,
  isCompleted,
}: SectionProps) => {
  return isCompleted ? (
    <Pane display={isSelected ? '' : 'none'}>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
      >
        <Pane flexGrow={1}>
          <Label appearance="primary">
            Have you applied for/received a loan from JN Small Business Loans
            (not JN Bank) in the last ONE year ?
          </Label>
          <Radio
            checked={formData.appliedBefore === 'Yes'}
            onChange={(e) => {
              setFormData('appliedBefore', e.target.value)
            }}
            size={16}
            name="entry.556918139"
            label="Yes"
            value="Yes"
            isRequired
          />
          <Radio
            checked={formData.appliedBefore === 'No'}
            onChange={(e) => {
              setFormData('appliedBefore', e.target.value)
            }}
            size={16}
            name="entry.556918139"
            label="No"
            value="No"
            isRequired
          />
        </Pane>
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
        marginTop={20}
      >
        <Pane flexGrow={1}>
          <Label appearance="primary">
            Do you hold an ACTIVE JN Bank account? (You can apply even if you
            are NOT an account holder)
          </Label>
          <Radio
            checked={formData.activeAccount === 'Yes'}
            onChange={(e) => {
              setFormData('activeAccount', e.target.value)
            }}
            size={16}
            name="entry.1978479179"
            label="Yes"
            value="Yes"
            isRequired
          />
          <Radio
            checked={formData.activeAccount === 'No'}
            onChange={(e) => {
              setFormData('activeAccount', e.target.value)
            }}
            size={16}
            name="entry.1978479179"
            label="No"
            value="No"
            isRequired
          />
        </Pane>
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
        marginTop={20}
      >
        <Pane flexGrow={1}>
          <Label appearance="primary">
            Have you visited our website,{' '}
            <Link href="https://jnsbl.com" target="_blank">
              www.jnsbl.com
            </Link>
            , to see our loan offerings ?
          </Label>
          <Radio
            checked={formData.visitedWebsite === 'Yes'}
            onChange={(e) => {
              setFormData('visitedWebsite', e.target.value)
            }}
            size={16}
            name="entry.293934752"
            label="Yes"
            value="Yes"
            isRequired
          />
          <Radio
            checked={formData.visitedWebsite === 'No'}
            onChange={(e) => {
              setFormData('visitedWebsite', e.target.value)
            }}
            size={16}
            name="entry.293934752"
            label="No"
            value="No"
            isRequired
          />
        </Pane>
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
        marginTop={20}
      >
        <Pane flexGrow={1}>
          <Label appearance="primary">
            How do you want to be contacted first ?
          </Label>
          <Radio
            checked={formData.contactMethod === 'Whatsapp'}
            onChange={(e) => {
              setFormData('contactMethod', e.target.value)
            }}
            size={16}
            name="entry.1074988337"
            label="Whatsapp"
            value="Whatsapp"
            isRequired
          />
          <Radio
            checked={formData.contactMethod === 'text'}
            onChange={(e) => {
              setFormData('contactMethod', e.target.value)
            }}
            size={16}
            name="entry.1074988337"
            label="Text"
            value="text"
            isRequired
          />
          <Radio
            checked={formData.contactMethod === 'email'}
            onChange={(e) => {
              setFormData('contactMethod', e.target.value)
            }}
            size={16}
            name="entry.1074988337"
            label="Email"
            value="email"
            isRequired
          />
          <Radio
            checked={formData.contactMethod === 'phonecall'}
            onChange={(e) => {
              setFormData('contactMethod', e.target.value)
            }}
            size={16}
            name="entry.1074988337"
            label="Phone Call"
            value="phonecall"
            isRequired
          />
        </Pane>
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        flexDirection={isWide ? 'row' : 'column'}
        marginTop={20}
      >
        <Pane flexGrow={1}>
          <Label appearance="primary">
            Do you want more information about JNSBL and our offerings ?
          </Label>
          <Radio
            checked={formData.moreInfo === 'Yes'}
            onChange={(e) => {
              setFormData('moreInfo', e.target.value)
            }}
            size={16}
            name="entry.274497923"
            label="Yes"
            value="Yes"
            isRequired
          />
          <Radio
            checked={formData.moreInfo === 'No'}
            onChange={(e) => {
              setFormData('moreInfo', e.target.value)
            }}
            size={16}
            name="entry.274497923"
            label="No"
            value="No"
            isRequired
          />
        </Pane>
      </Pane>
      <Pane display="flex" justifyContent="space-between">
        <Button
          height={40}
          onClick={(e: any) => {
            e.preventDefault()
            setActiveSection([0, 1])
            setSelectedSection(1)
          }}
          marginTop={20}
          appearance="primary"
          color="white"
          style={{
            backgroundImage: 'linear-gradient(39deg, #1453A8 0%, #124ea9 100%)',
          }}
        >
          Previous
        </Button>
        <Button
          type="submit"
          height={40}
          onClick={(e: any) => {}}
          marginTop={20}
          isLoading={isLoading}
          appearance="primary"
          color="white"
          style={{
            backgroundImage: isLoading
              ? 'none'
              : 'linear-gradient(39deg, #1453A8 0%, #124ea9 100%)',
          }}
        >
          Submit
        </Button>
      </Pane>
    </Pane>
  ) : null
}

const Form: React.FC = () => {
  const history = useHistory()
  const isWide = useMedia({ minWidth: 600 })

  const [isLoading, setLoadingStatus] = useState(false)
  const [isSubmitted, setSubmissionStatus] = useState(false)
  const [activeSections, setActiveSection] = useState([0])
  const [selectedSection, setSelectedSection] = useState(0)
  const formRef = useRef(null)
  const [formData, dispatch] = useReducer(reducer, formFields)
  const tabs = [
    {
      index: 0,
      key: 'section-a',
      name: 'Tell Us About Yourself',
      component: (props: SectionProps) => (
        <SectionOne {...props} key="section-a" />
      ),
    },
    {
      index: 1,
      key: 'section-b',
      name: 'What Service Are You Interested In',
      component: (props: SectionProps) => (
        <SectionTwo {...props} key="section-b" />
      ),
    },
    {
      index: 2,
      key: 'section-c',
      name: 'A Little More Info to Help Serve You',
      component: (props: SectionProps) => (
        <SectionThree {...props} key="section-c" />
      ),
    },
  ]
  const setFormData = (name: string, value: string) => {
    dispatch({ field: name, value })
  }

  return (
    <Pane>
      <Header />
      <Content styles={{ flexDirection: 'column' }}>
        <Pane top={-30} zIndex={-1} width="100%" position="absolute">
          <Home />
        </Pane>
        <Pane
          background="blueTint"
          elevation={0}
          width={isWide ? 720 : 360}
          height={120}
          margin={24}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          padding={15}
          borderRight={'muted'}
          borderBottom={'muted'}
        >
          <Text fontWeight={600} marginBottom={15} fontSize={16}>
            Thank you for interacting with JN Small Business Loans online!
          </Text>
          <Text fontSize={12} textAlign={'center'}>
            We want to get you the info that's just right for you the first
            time, so please complete the form below as accurately as possible
            and we will be in touch soon!
          </Text>
        </Pane>
        <Pane
          elevation={4}
          display="flex"
          minHeight={620}
          borderRadius={5}
          marginBottom={30}
          backgroundColor="white"
          width={isWide ? 720 : 360}
        >
          <iframe
            title="hidden_iframe"
            name="hidden_iframe"
            id="hidden_iframe"
            style={{ display: 'none' }}
            onLoad={(e) => {
              if (isSubmitted) {
                setLoadingStatus(false)
                history.push('/thank-you')
              }
            }}
          />
          <Pane
            innerRef={formRef}
            is="form"
            width="100%"
            paddingX={35}
            paddingY={35}
            display="flex"
            target="hidden_iframe"
            flexDirection="column"
            justifyContent="space-evenly"
            onSubmit={(e: any) => {
              setLoadingStatus(true)
              setSubmissionStatus(true)
            }}
            action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfpq120rokDuX3VIX-WehEzUDsF2xle2u8EzmcnKawzSgm6Og/formResponse"
          >
            <Tablist
              marginBottom={36}
              paddingBottom={18}
              flexWrap="wrap"
              display="flex"
              alignItems="center"
              justifyContent="center"
              borderBottom="1px solid #eee"
            >
              {tabs.map((tab, index) => (
                <React.Fragment key={tab.key}>
                  <Tab
                    margin={!isWide && 10}
                    width={!isWide && '100%'}
                    id={tab.key}
                    onSelect={() => {
                      if (activeSections.includes(index)) {
                        return setSelectedSection(index)
                      } else {
                        toaster.notify('Complete the current section first')
                        return null
                      }
                    }}
                    isSelected={index === selectedSection}
                    aria-controls={`panel-${tab.key}`}
                  >
                    {tab.name}
                  </Tab>
                  {index < tabs.length - 1 && isWide && (
                    <Icon icon="double-chevron-right" />
                  )}
                  {index < tabs.length - 1 && !isWide && (
                    <Icon icon="double-chevron-down" />
                  )}
                </React.Fragment>
              ))}
            </Tablist>
            {tabs.map((tab, idx) =>
              tab.component({
                isWide,
                isLoading,
                setActiveSection,
                setSelectedSection,
                formRef,
                setFormData,
                formData,
                isSelected: selectedSection === idx,
                isCompleted: activeSections.includes(idx),
              }),
            )}
          </Pane>
        </Pane>
      </Content>
    </Pane>
  )
}

export default Form
