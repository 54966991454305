// External Dependencies
import React from 'react'
import { Pane } from 'evergreen-ui'
import { useMedia } from 'react-use-media'

// Our Dependencies
//import { Timer } from 'components'
import logo from 'assets/images/logo.png'
import startSocialLogo from 'assets/images/sslogo.png'

type Props = {
  bottomComponent?: any
}

const Header = ({ bottomComponent }: Props) => {
  const isWide = useMedia({ minWidth: 600 })

  return (
    <Pane display="flex" justifyContent="center" position="relative">
      <Pane
        height={350}
        width="100%"
        display="flex"
        overflow="hidden"
        position="relative"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        backgroundImage="linear-gradient(190deg, #1453A8 0%, #113c7d 100%)"
      >
        <Pane
          top={-120}
          width={200}
          right={-100}
          height={200}
          position="absolute"
          transform="rotate(45deg)"
          backgroundImage="linear-gradient(57deg, #FEF102 0%, #d6cb00 100%)"
        />
        <Pane
          left={-105}
          width={200}
          height={200}
          bottom={-100}
          position="absolute"
          transform="rotate(226deg)"
          backgroundImage="linear-gradient(57deg, #FEF102 0%, #d6cb00 100%)"
        />
        <Pane>
          <img src={logo} alt="logo" width={'175px'} />
        </Pane>

        <Pane
          is="h1"
          textAlign="center"
          fontFamily="Bozon"
          marginTop={0}
          marginBottom={5}
          fontSize={isWide ? 45 : 30}
        >
          <Pane
            // is="span"
            // color="white"
            // fontWeight={700}
            // display={isWide ? 'inline' : 'block'}
            is="span"
            marginLeft={10}
            fontWeight={400}
            style={{
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
            background="linear-gradient(57deg, #FEF102 0%, #d6cb00 100%)"
          >
            SOCIAL MEDIA
          </Pane>
          <Pane
            is="span"
            marginLeft={10}
            fontWeight={400}
            style={{
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
            background="linear-gradient(57deg, #FEF102 0%, #d6cb00 100%)"
          >
            CONTACT FORM
          </Pane>
        </Pane>
        <Pane is="span" color="white" fontFamily="Bozon" fontSize={12}>
          Powered by
        </Pane>
        <Pane>
          <img src={startSocialLogo} alt="logo" width={'210px'} />
        </Pane>
      </Pane>

      {bottomComponent && (
        <Pane top={321} position="absolute" style={{ zIndex: '1' }}>
          <Pane
            top={12}
            left={57}
            height={68}
            width={300}
            borderRadius={5}
            background="linear-gradient(150deg, #f5e800 0%, #e0d500 100%)"
          >
            {bottomComponent}
          </Pane>
        </Pane>
      )}
    </Pane>
  )
}

export default Header
