// External Dependencies
import React from 'react'
import { Pane, Link } from 'evergreen-ui'

// Our Dependencies
import { Header, Content } from 'components'
import { ReactComponent as Humans } from 'assets/images/two-humans.svg'
import { ReactComponent as TickIcon } from 'assets/images/tick-circle.svg'

const CompletedForm: React.FC = () => {
  return (
    <Pane>
      <Header />
      <Content>
        <Pane
          marginTop={30}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <TickIcon />

          <Pane
            is="h2"
            fontSize={36}
            color="#3D3F5A"
            fontWeight={500}
            textAlign="center"
            fontFamily="Bozon"
          >
            Thank You, <br /> We'll be in touch
            <br />
            <br />
            <Pane is="span" fontSize={24}>
              Feel free to visit our website for more information:
            </Pane>
            <Link
              style={{ textDecoration: 'none' }}
              fontSize={24}
              href="https://www.jnsbl.com/"
              target="_blank"
            >
              www.jnsbl.com
            </Link>
            <Pane
              is="h2"
              fontSize={24}
              color="#3D3F5A"
              fontWeight={500}
              textAlign="center"
              fontFamily="Bozon"
              lineHeight={'1.5em'}
            >
              <Pane is="span" marginBottom={20}>
                Contact Us
              </Pane>
              <br />
              Landline: (876) 948- 7454-5 / (876) 618-1596 <br />
              Toll-free: (888)-725-6267 <br />
              Whatsapp: (876)295-1298
            </Pane>
          </Pane>
        </Pane>
      </Content>
      <Pane zIndex={-1} display="flex" justifyContent="center">
        <Humans />
      </Pane>
    </Pane>
  )
}

export default CompletedForm
