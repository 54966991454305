// Dependencies
import React, { useState } from 'react'
import { TextInputField } from 'evergreen-ui'

type Props = {
  name?: string
  label: string
  placeholder?: any
  inputWidth: string
  value?: string | number
  isRequired?: boolean
  onChange?: (event: React.SyntheticEvent) => void
}

const PhoneNumberInput = ({
  name,
  label,
  onChange,
  value = '',
  inputWidth,
  placeholder,
  isRequired,
}: Props) => {
  const [phoneNumber, setPhoneNumber] = useState(value)

  const handleChange = (event: React.SyntheticEvent) => {
    event.persist()

    let { value } = event.target as HTMLInputElement
    const cleaned = value.toString().replace(/\D/g, '')

    let formattedValue = ''

    if (cleaned.length > 10) {
      return
    }

    if (cleaned.length > 6) {
      // prettier-ignore
      formattedValue = `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}-${cleaned.substring(6, 10)}`
    } else if (cleaned.length > 3) {
      formattedValue = `(${cleaned.substring(0, 3)}) ${cleaned.substring(3, 6)}`
    } else {
      formattedValue = `${cleaned.substring(0, 3)}`
    }

    setPhoneNumber(formattedValue)
    onChange && onChange(event)
  }

  return (
    <TextInputField
      label={label}
      inputHeight={38}
      autoComplete="off"
      value={phoneNumber}
      required={isRequired}
      inputWidth={inputWidth}
      onChange={handleChange}
      name={name || 'phoneNumber'}
      placeholder={placeholder || '(876) 999-9999'}
    />
  )
}

export default PhoneNumberInput
