// External Dependencies
import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

// Our Dependencies
import Metrics from './components/Metrics'
import {
  Form,
  CompletedForm,
  //Expiration
} from 'components'

const App: React.FC = () => {
  return (
    <Router basename="/">
      <Switch>
        <Route exact path="/thank-you">
          <CompletedForm />
        </Route>
        <Route exact path="/metrics">
          <Metrics />
        </Route>
        <Route exact path="/">
          <Form />
        </Route>
      </Switch>
    </Router>
  )
}

export default App
